@import '../../styles/_typography'
@import  '../../styles/_variables'
.aboutHero
    position: relative
    padding-top: 90px
    height: 100vh
    @media screen and (max-width: 850px)
        padding-top: 0
    .aboutHeroImage
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        .img
            width: 100%
            height: 100%
            object-fit: cover
    .aboutHeroContent
        position: relative
        z-index: 1
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        backdrop-filter: brightness(0.5)
        .contentContainer
            width: 100%
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column
            row-gap: 50px
            @media screen and (max-width: 768px)
                row-gap: 25px

            .contentTitle
                .title
                    +typography(64px, 600, map-get($basic, 'white'))
                    @media screen and (max-width: 1250px)
                        font-size: 52px
                    @media screen and (max-width: 768px)
                        font-size: 32px
                    @media screen and (max-width: 550px)
                        font-size: 24px
            .contentSubtitle
                text-align: center
                .subtitle
                    +typography(18px, 400, map-get($basic, 'white'))
                    @media screen and (max-width: 768px)
                        font-size: 16px
