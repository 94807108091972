@import '../../styles/_typography'
@import '../../styles/_variables'
.certificates
    padding: 70px 0
    @media screen and (max-width: 1250px)
        padding: 50px 0
        background: linear-gradient( to right, #042d2e 0%,#031011 38%,#030505 53%,#0a1b1b 67%,#1a4f50 100%)
    .certificatesContainer
        display: flex
        align-items: center
        justify-content: center
        flex-direction: row
        @media screen and (max-width: 768px)
            flex-direction: column
            row-gap: 50px
        @media screen and (max-width: 550px)
            row-gap: 25px
        .certificatesContent
            display: flex
            flex-direction: column
            row-gap: 30px
            width: 100%
            .contentTitle
                .title
                    +typography(32px, 600, map-get($basic, 'white'))
                    @media screen and (max-width: 1250px)
                        font-size: 28px
                    @media screen and (max-width: 550px)
                        font-size: 21px
            .contentSubtitle
                .subtitle
                    +typography(18px, 400, map-get($basic, 'white'))
                    @media screen and (max-width: 1250px)
                        font-size: 16px
        .certificatesImage
            width: 100%
            .img
                width: 100%
