@import '../../styles/_typography'
@import  '../../styles/_variables'
.footer
    padding: 70px 0
    @media screen and (max-width: 868px)
        padding: 50px 0
    .footerContainer
        display: flex
        align-items: start
        column-gap: 30px
        justify-content: space-between
        @media screen and (max-width: 867px)
            flex-wrap: wrap
            row-gap: 50px
        @media screen and (max-width: 550px)
            flex-direction: column
        .footerItems
            display: flex
            flex-direction: column
            row-gap: 20px
            @media screen and (max-width: 867px)
                &:nth-child(1)
                    order: 1
                    width: 100%
                &:nth-child(2)
                    order: 2
                &:nth-child(3)
                    order: 3
            .footerTitle
                .title
                    text-transform: uppercase
                    +typography(20px, 600, map-get($basic, 'white'))
                    @media screen and (max-width: 1250px)
                        font-size: 18px
            .footerLinks
                display: flex
                flex-direction: column
                row-gap: 10px
                .links
                    .link
                        cursor: pointer
                        text-decoration: underline
                        +typography(18px, 400, map-get($basic, 'white'))
                        @media screen and (max-width: 1250px)
                            font-size: 16px
            .footerLinksTitle
                .title
                    +typography(18px, 400, map-get($basic, 'white'))
                    @media screen and (max-width: 1250px)
                        font-size: 16px
            .footerNav
                display: flex
                flex-direction: column
                row-gap: 20px
                .links
                    .navLink
                        text-decoration: none
                        +typography(18px, 400, map-get($basic, 'white'))
                        @media screen and (max-width: 1250px)
                            font-size: 16px
.ant-modal-content,
.ant-modal-header
    background: map-get($basic, 'black') !important
    .ant-modal-title
        +typography(32px, 400, map-get($basic, 'white'))
        @media screen and (max-width: 1250px)
            font-size: 28px
        @media screen and (max-width: 768px)
            font-size: 18px
.ant-modal-footer
    display: none !important
