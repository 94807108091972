@import '../../../styles/_typography'
@import '../../../styles/_variables'
.newsSliderContainer
    padding-top: 30px
    .newsSlider
        display: flex
        flex-direction: column-reverse
        row-gap: 50px
        .sliderHeader
            display: flex
            justify-content: space-between
            align-items: center
            @media screen and (max-width: 550px)
                flex-direction: column
                row-gap: 20px
                justify-content: start
                align-items: flex-start
            .headerMore
                .link
                    +typography(18px, 400, map-get($basic, 'white'))
                    text-decoration: none
                    transition: all 0.5s ease
                    position: relative
                    &::before
                        position: absolute
                        content: ''
                        display: block
                        width: 100%
                        height: 2px
                        bottom: -5px
                        left: 0
                        transition: all 0.5s ease
                    &:hover
                        color: #3DC1C3
                        &::before
                            background: #3DC1C3
            .btnsGroup
                left: -100%
                right: inherit
                bottom: 20px
                top: inherit
                display: flex
                flex-direction: row
                align-items: center
                column-gap: 10px
                .swiper-button-prev,
                .swiper-button-next
                    cursor: pointer
                    color: map-get($basic, 'white')
                    transition: all 0.5s ease
                    svg
                        font-size: 24px
                    &:hover
                        color: map-get($main, 'main')
                .swiper-button-prev
                    top: 0
                    transform: rotate(-90deg)

                .swiper-button-next
                    bottom: 0
                    transform: rotate(-90deg)

                .swiper-pagination
                    display: flex
                    align-items: center
                    flex-direction: row
                    column-gap: 10px
                    &-bullet
                        width: 14px
                        height: 14px
                        background-color: map-get($main, 'lightWhite')
                        border-radius: 50%
                        margin: 10px 0
                        display: flex
                        justify-content: center
                        align-items: center
                        color: map-get($main, "main" )
                        font-weight: bold
                        font-size: 14px
                        transition: background-color 0.3s, transform 0.3s
                        cursor: pointer

                    &-bullet-active
                        width: 14px
                        height: 14px
                        background-color: map-get($main, "main" )
                        transform: scale(1.2)

        .newsCard
            max-width: 450px
            width: 100%
            background: rgba(0, 0, 0, 0.3)
            border-radius: 6px
            transition: all 0.5s ease
            @media screen and (max-width: 768px)
                width: 100%
                max-width: 100%
            &:hover
                background: map-get($main, 'main')
            .cardContainer
                .cardImage
                    max-width: 450px
                    width: 100%
                    height: 230px
                    border-radius: 6px 6px 0 0
                    @media screen and (max-width: 768px)
                        width: 100%
                        max-width: 100%
                    .img
                        border-radius: 6px 6px 0 0
                        width: 100%
                        height: 100%
                        object-fit: cover
                .cardContent
                    padding: 20px 30px
                    display: flex
                    flex-direction: column
                    row-gap: 20px
                    @media screen and (max-width: 768px)
                        padding: 20px 10px
                    .contentTitle
                        text-align: center
                        @media screen and (max-width: 1250px)
                            text-align: start
                        .title
                            +typography(24px, 400, map-get($basic, 'white'))
                            @media screen and (max-width: 1250px)
                                font-size: 20px
                    .contentText
                        .text
                            line-height: 27px
                            display: -webkit-box
                            overflow: hidden
                            text-overflow: ellipsis
                            -webkit-line-clamp: 4
                            -webkit-box-orient: vertical
                            +typography(18px, 400, map-get($basic, 'white'))
                .contentSeeMore
                    padding: 12px 30px
                    @media screen and (max-width: 768px)
                        padding: 12px 10px
                    .link
                        text-decoration: none
                        +typography(18px, 400, map-get($basic, 'white'))
