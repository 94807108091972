@import '../../styles/_typography'
@import '../../styles/_variables'
.resourcesCards
    padding: 50px 0
    .pagination
        margin-top: 50px
    .resourcesCardsContainer
        display: flex
        gap: 50px
        justify-content: space-between
        flex-wrap: wrap
        .card
            max-width: 720px
            width: 100%
            border-radius: 6px
            @media screen and (max-width: 1675px)
                max-width: 650px
                width: 100%
            @media screen and (max-width: 1530px)
                max-width: 550px
            @media screen and (max-width: 1350px)
                max-width: 500px
            @media screen and (max-width: 1100px)
                max-width: 450px
            @media screen and (max-width: 991px)
                max-width: 100%
            .cardLink
                text-decoration: none
                .cardContainer
                    transition: all 0.5s ease
                    background: map-get($basic, 'black')
                    &:hover
                        background: map-get($main, 'main')
            .cardImage
                .img
                    width: 100%
            .cardContent
                padding: 20px
                display: flex
                flex-direction: column
                row-gap: 30px
                .contentTitle
                    .title
                        +typography(24px, 500, map-get($basic, 'white'))
                        @media screen and (max-width: 550px)
                            font-size: 20px
                .contentText
                    .text
                        +typography(18px, 500, map-get($basic, 'white'))
                        @media screen and (max-width: 550px)
                            font-size: 16px
                .contentLinkMore
                    .link
                        text-decoration: none
                        +typography(18px, 400, map-get($basic, 'white'))
                        @media screen and (max-width: 550px)
                            font-size: 16px
