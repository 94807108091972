@import '../../styles/_typography'
@import  '../../styles/_variables'
.projectInnerHero
    position: relative
    padding-top: 90px
    .projectHeroImage
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        .img
            width: 100%
            height: 100%
            object-fit: cover
    .backdropFilter
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        backdrop-filter: brightness(0.5)
    .projectHeroContent
        position: relative
        z-index: 1
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        padding: 100px 0
        .projectHeroTitle
            display: flex
            justify-content: center
            align-items: center
            .title
                +typography(32px, 600, map-get($basic, 'white'))
                @media screen and (max-width: 1250px)
                    font-size: 28px
                @media screen and (max-width: 550px)
                    font-size: 21px
