@import '../../styles/_typography'
@import '../../styles/_variables'
.newsCard
    max-width: 450px
    width: 100%
    background: rgba(0, 0, 0, 0.3)
    border-radius: 6px
    transition: all 0.5s ease
    cursor: pointer
    @media screen and (max-width: 768px)
        width: 100%
        max-width: 100%
    &:hover
        background: map-get($main, 'main')
    .cardContainer
        .cardImage
            max-width: 450px
            width: 100%
            height: 230px
            border-radius: 6px 6px 0 0
            @media screen and (max-width: 768px)
                width: 100%
                max-width: 100%
            .img
                border-radius: 6px 6px 0 0
                width: 100%
                height: 100%
                object-fit: cover
        .cardContent
            padding: 20px 30px
            display: flex
            flex-direction: column
            row-gap: 20px
            @media screen and (max-width: 768px)
                padding: 20px 10px
            .contentTitle
                text-align: center
                @media screen and (max-width: 1250px)
                    text-align: start
                .title
                    +typography(24px, 400, map-get($basic, 'white'))
                    @media screen and (max-width: 1250px)
                        font-size: 20px
            .contentText
                .text
                    line-height: 27px
                    display: -webkit-box
                    overflow: hidden
                    text-overflow: ellipsis
                    -webkit-line-clamp: 4
                    -webkit-box-orient: vertical
                    +typography(18px, 400, map-get($basic, 'white'))
        .contentSeeMore
            padding: 12px 30px
            @media screen and (max-width: 768px)
                padding: 12px 10px
            .link
                text-decoration: none
                +typography(18px, 400, map-get($basic, 'white'))
