@import '../../styles/_typography'
@import  '../../styles/_variables'
.productInner
    padding: 70px 0
    @media screen and (max-width: 1250px)
        padding: 50px 0
    .productInnerContainer
        .productInnerSlider
            width: 100%
            height: 100%
            .productImage
                // width: 300px
                height: 400px
                .img
                    width: 100%
                    height: 100%
                    object-fit: cover
        .productImage
            width: 100%
            .img
                max-height: 500px
                width: 100%
                object-fit: cover
