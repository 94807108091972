@import '../../styles/_typography'
@import  '../../styles/_variables'
.contactUs
    position: relative
    width: 100%
    .contactUsImage
        position: absolute
        width: 100%
        height: 100%
        .img
            width: 100%
            height: 100%
            object-fit: cover
    .contactUsContainer
        position: relative
        z-index: 1
        padding: 70px 0
        backdrop-filter: brightness(0.3)
        @media screen and (max-width: 768px)
            padding: 50px 0
        .contactUsContentForm
            display: flex
            flex-direction: column
            row-gap: 30px
            .contactsHeader
                display: flex
                flex-direction: column
                row-gap: 10px
                .contactsTitle
                    .title
                        +typography(32px, 600, map-get($basic, 'white'))
                        @media screen and (max-width: 1250px)
                            font-size: 28px
                        @media screen and (max-width: 550px)
                            font-size: 21px
                .contactsSubtitle
                    .subtitle
                        +typography(20px, 400, map-get($basic, 'white'))
                        @media screen and (max-width: 1050px)
                            font-size: 16px
            .formItemsContainer
                padding: 40px
                backdrop-filter: blur(30px)
                background: rgba(255, 255, 255, 0.2)
                display: flex
                flex-direction: column
                row-gap: 50px
                border-radius: 6px
                @media screen and (max-width: 768px)
                    padding: 20px
                .formInputs
                    display: flex
                    flex-direction: row
                    column-gap: 50px
                    flex-wrap: wrap
                    row-gap: 50px
                    .formFields
                        display: flex
                        flex-direction: column
                        row-gap: 50px
                        width: 45%
                        @media screen and (max-width: 768px)
                            width: 100%
                        &:last-child
                            width: 100%
                        .input
                            background: transparent
                            outline: none
                            border: none
                            border-bottom: 1px solid map-get($basic, 'white')
                            +typography(24px, 400, map-get($basic, 'white'))
                            padding: 15px 10px
                            &::placeholder
                                +typography(24px, 400, map-get($basic, 'white'))
                .formBtn
                    max-width: 230px
                    @media screen and (max-width: 768px)
                        max-width: 100%

            .formCheckbox
                display: flex
                align-items: center
                column-gap: 10px
                .text
                    line-height: 24px
                    +typography(18px, 400, map-get($basic, 'white'))
                    @media screen and (max-width: 1250px)
                        font-size: 16px
                    @media screen and (max-width: 768px)
                        font-size: 12px
                    @media screen and (max-width: 550px)
                        font-size: 10px
                    .underline
                        text-decoration: underline
