@import '../../styles/_typography'
@import  '../../styles/_variables'
.blogItems
    .pagination
        margin-top: 50px
    .blogItemsBody
        display: flex
        justify-content: space-between
        column-gap: 50px
        width: 100%
        .blogItemsContainer
            width: 100%
            display: flex
            flex-direction: column
            row-gap: 50px
            .blogItem
                background: #151515
                padding: 20px
                border-radius: 6px
                .blogContent
                    display: flex
                    width: 100%
                    column-gap: 20px
                    .contentImage
                        max-width: 400px
                        width: 100%
                        .img
                            width: 100%
                    .content
                        width: 100%
                        display: flex
                        flex-direction: column
                        justify-content: space-between
                        .contentTitle
                            .title
                                +typography(24px, 500, map-get($basic, 'white'))
                        .contentText
                            max-width: 600px
                            width: 100%
                            .text
                                line-height: 1.5
                                +typography(18px, 400, map-get($basic, 'white'))
                        .contactTag
                            .tag
                                +typography(24px, 500, map-get($basic, 'white'))
                .blogInfo
                    display: flex
                    justify-content: space-between
                    .info
                        display: flex
                        align-content: center
                        justify-content: center
                        column-gap: 20px
                        margin-top: 16px
                        .writer
                            display: flex
                            align-items: center
                            justify-content: center
                            column-gap: 10px
                            .writerAvatar
                                width: 50px
                                height: 50px
                                background: map-get($basic, 'black')
                                border-radius: 50px
                                display: flex
                                align-items: center
                                justify-content: center
                            .writerName
                                .name
                                    +typography(18px, 500, map-get($basic, 'white'))
                        .infoDate
                            display: flex
                            align-items: center
                            justify-content: center
                            column-gap: 5px
                            .infoDateText,
                            .time
                                +typography(18px, 500, map-get($basic, 'white'))
                    .link
                        padding: 10px 20px
                        background: map-get($main, 'main')
                        border: 2px solid map-get($main, 'main')
                        display: flex
                        align-items: center
                        justify-content: center
                        border-radius: 6px
                        transition: all 0.5s ease
                        text-decoration: none
                        .linkSeeMore
                            +typography(18px, 400, map-get($basic, 'white'))
                        &:hover
                            background: transparent
        .blogFilter
            width: 20%
