@import '../../../styles/_typography'
@import '../../../styles/_variables'
.newsSliderContainer
    padding-top: 30px
    .internationalSwiperSlider
        display: flex
        flex-direction: column
        row-gap: 50px
        .sliderHeader
            display: flex
            justify-content: center
            align-items: center
            .btnsGroup
                left: -100%
                right: inherit
                bottom: 20px
                top: inherit
                display: flex
                flex-direction: row
                align-items: center
                column-gap: 10px
                .swiper-button-prev,
                .swiper-button-next
                    cursor: pointer
                    color: map-get($basic, 'white')
                    transition: all 0.5s ease
                    svg
                        font-size: 24px
                    &:hover
                        color: map-get($main, 'main')
                .swiper-button-prev
                    top: 0
                    transform: rotate(-90deg)

                .swiper-button-next
                    bottom: 0
                    transform: rotate(-90deg)

                .swiper-pagination
                    display: flex
                    align-items: center
                    flex-direction: row
                    column-gap: 10px
                    &-bullet
                        width: 14px
                        height: 14px
                        background-color: map-get($main, 'lightWhite')
                        border-radius: 50%
                        margin: 10px 0
                        display: flex
                        justify-content: center
                        align-items: center
                        color: map-get($main, "main" )
                        font-weight: bold
                        font-size: 14px
                        transition: background-color 0.3s, transform 0.3s
                        cursor: pointer

                    &-bullet-active
                        width: 14px
                        height: 14px
                        background-color: map-get($main, "main" )
                        transform: scale(1.2)

        .internationalCard
            max-width: 450px
            width: 100%
            border-radius: 6px
            transition: all 0.5s ease
            @media screen and (max-width: 768px)
                width: 100%
                max-width: 100%
            .benefitsCard
                background-color: transparent
                width: 300px
                height: 250px
                perspective: 1000px
                border-radius: 20px
                background: transparent
                cursor: pointer
                @media screen and (max-width: 635px)
                    width: 100%
                .benefitsCardInner
                    position: relative
                    width: 100%
                    height: 100%
                    transition: transform 0.6s
                    transform-style: preserve-3d
                    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2)
                &:hover
                    .benefitsCardInner
                        transform: rotateY(180deg)
                .flipCardFront, .flipCardBack
                    position: absolute
                    width: 100%
                    height: 100%
                    -webkit-backface-visibility: hidden
                    backface-visibility: hidden
                    display: flex
                    align-items: flex-start
                    flex-direction: column
                    row-gap: 10px
                    justify-content: center
                    padding: 20px
                .flipCardFront
                    border-radius: 6px
                    background: map-get($main, 'main')
                    .cardTitle
                        .title
                            +typography(24px, 600, map-get($basic, 'white'))
                    .cardText
                        .text
                            line-height: 27px
                            display: -webkit-box
                            overflow: hidden
                            text-overflow: ellipsis
                            -webkit-line-clamp: 4
                            -webkit-box-orient: vertical
                            +typography(18px, 400, map-get($basic, 'white'))
                .flipCardBack
                    padding: 20px
                    display: flex
                    justify-content: center
                    align-items: center
                    border-radius: 6px
                    background: map-get($main, 'main')
                    transform: rotateY(180deg)
                    .cardText
                        .text
                            +typography(18px, 400, map-get($basic, 'white'))
