@import '../../styles/_typography'
@import  '../../styles/_variables'
.hero
    position: relative
    height: 100vh
    .heroImage
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        .img
            width: 100%
            height: 100%
            object-fit: cover
    .mainHero
        height: 100%
        width: 100%
        position: relative
        display: flex
        justify-content: end
        z-index: 1
        .mainHeroContainer
            width: 100%
            max-width: 1200px
            display: flex
            align-items: center
            justify-content: end
            background: rgba(0, 0, 0, 0.5)
            .heroContent
                display: flex
                flex-direction: column
                justify-content: end
                align-items: end
                row-gap: 50px
                @media screen and (max-width: 1200px)
                    align-items: center
                    justify-content: center
                @media screen and (max-width: 768px)
                    row-gap: 25px
                @media screen and (max-width: 550px)
                    align-items: flex-start
                .heroTitle
                    text-align: right
                    @media screen and (max-width: 1200px)
                        text-align: center
                    @media screen and (max-width: 550px)
                        text-align: start
                    .title
                        line-height: 96px
                        +typography(64px, 800, map-get($basic, 'white'))
                        @media screen and (max-width: 1150px)
                            font-size: 52px
                            line-height: 69px
                        @media screen and (max-width: 768px)
                            font-size: 32px
                            line-height: 52px
                        @media screen and (max-width: 550px)
                            font-size: 24px
                            line-height: 42px
                        @media screen and (max-width: 550px)
                            font-size: 20px
                            line-height: 36px
                .heroSubtitle
                    text-align: right
                    @media screen and (max-width: 1200px)
                        text-align: center
                    @media screen and (max-width: 550px)
                        text-align: start
                    .subtitle
                        line-height: 36px
                        +typography(24px, 400, map-get($main, 'lightWhite'))
                        @media screen and (max-width: 1150px)
                            font-size: 24px
                        @media screen and (max-width: 768px)
                            font-size: 20px
                        @media screen and (max-width: 550px)
                            font-size: 18px
                            line-height: 32px
                        @media screen and (max-width: 550px)
                            font-size: 16px
                            line-height: 28px
                .heroCallBtn
                    max-width: 240px
                    @media screen and (max-width: 1200px)
                        max-width: 100%
                    .link
                        text-decoration: none
