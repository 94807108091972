@import '../../styles/_typography'
@import  '../../styles/_variables'
.header
    width: 100%
    position: fixed
    top: 0
    z-index: 6
    transition: all 0.5s ease
    background: map-get($basic, 'black')
    .headerItems
        position: relative
        display: flex
        justify-content: space-between
        @media(max-width: 768px)
            height: 40px
        .burgerBtnContainer
            display: none
            @media screen and (max-width: 768px)
                display: flex
                justify-content: center
                width: 30px
                z-index: 2
            .burgerBtn
                width: 22px
                height: 24px
                position: relative
                &::before, &::after, span
                    transition: all 0.3s ease 0s
                    content: ""
                    height: 2px
                    background-color: #acaaaa
                    position: absolute
                    left: 0
                    border-radius: 2px
                    width: 100%
                &::before
                    top: 5px
                span
                    top: 50%
                    transform: translateY(-2px)
                &::after
                    bottom: 6px
            .burgerBtn.active
                width: 20px
                height: 20px
                position: relative
                &:before,
                &:after
                    left: 0
                    right: 0
                    bottom: 0
                    top: 0
                    margin: auto
                    position: absolute
                    width: 100%
                    height: 1px
                    background-color: #acaaaa
                &:before
                    transform: rotate(-45deg)
                &::after
                    transform: rotate(45deg)
                span
                    opacity: 0
        .headerItem
            display: flex
            flex-direction: row
            align-items: center
            column-gap: 20px
            padding: 20px 0
            width: 40%
            @media screen and (max-width: 768px)
                column-gap: 20px
            .headerLogo
                display: flex
                align-items: flex-end
                flex-direction: row
                column-gap: 13px
                text-decoration: none
                width: 100%
                .logo
                    @media screen and (max-width: 768px)
                        max-width: 100px
                        width: 100%

                    // @media screen and (max-width: 550px)
                    //     width: 100px
                    // @media screen and (max-width: 380px)
                    //     width: 80px
            .mobileOverlay
                @media screen and (max-width: 768px)
                    transform: translateX(-100%)
                    position: fixed
                    display: block
                    top: 40px
                    right: 0
                    width: 100%
                    height: 100%
                    background: #000000ad
                    transition: all 0.5s ease
                &.activeMenu
                    transform: translateX(0%)
            .nav
                // margin: 0 0 0 auto
                transition: all 0.5s ease
                .headerNavBtn
                    display: none
                @media screen and (max-width: 768px)
                    transform: translateX(100%)
                    position: fixed
                    display: block
                    top: 40px
                    right: 0
                    width: 100%
                    height: 100%
                    z-index: 1
                    .navContainer
                        position: absolute
                        right: 0
                        height: 100dvh
                        width: 100%
                        background: map-get($basic, 'black')
                        padding: 20px
                        display: flex
                        flex-direction: column
                        justify-content: space-between
                        row-gap: 30px
                        .headerNavBtn
                            margin-bottom: 100px
                        .headerLogo
                            display: flex
                        .links
                            display: flex
                            flex-direction: column
                            padding-top: 40px
                            row-gap: 20px
                            .navLink
                                border-bottom: 1px solid #81818126
                                padding-bottom: 10px
                                +typography(40px, 600, map-get($basic, 'white'))
                                &:hover
                                    color: map-get($main, 'red')
                .nav-box
                    .headerLogo
                        display: none
                    @media screen and (max-width: 768px)
                        display: flex
                        flex-direction: column
                        row-gap: 16px
                .navLink
                    +typography(18px, 300, map-get($basic, 'white'))
                    text-decoration: none
                    display: inline-block
                    position: relative
                    transition: all 0.25s ease-out
                    @media screen and (max-width: 991px)
                        font-size: 15px
                    &:hover
                        color: map-get($main, 'red')
                    @media screen and (max-width: 1350px)
                        font-size: 14px
                    @media screen and (max-width: 991px)
                        font-size: 13px
                    @media screen and (max-width: 640px)
                        font-size: 14px
                .navLink:not(:last-child)
                    margin-right: 25px
                    @media screen and (max-width: 1350px)
                        margin-right: 20px
                    @media screen and (max-width: 768px)
                        margin-right: 0
                &.activeMenu
                    transform: translateX(0)
        .mainBgColor
            background: map-get($main, 'main' )
            display: flex
            width: 60%
            justify-content: center
            align-items: center
            padding: 0 60px
            column-gap: 0
            position: relative
            @media screen and (max-width: 1250px)
                padding: 0 20px
            @media screen and (max-width: 875px)
                width: 85%
            @media screen and (max-width: 768px)
                width: 50%
                justify-content: flex-end
                column-gap: 20px
            &::before
                content: ''
                position: absolute
                left: -28px
                top: 0
                bottom: 0
                width: 30px
                background-color: map-get($main, 'main')
                clip-path: polygon(100% 0, 0 100%, 100% 100%)
                @media screen and (max-width: 768px)
                    display: none
        .headerButton
            display: flex
            align-items: center
            border-radius: 50px
            padding: 0 20px
            text-decoration: none
            a
                text-decoration: none
            .btn
                @media screen and (max-width: 850px)
                    font-size: 13px
                    width: 150px
            @media screen and (max-width: 850px)
                padding: 0
            @media screen and (max-width: 768px)
                // margin-right: 20px
                display: none

.scrolledHeaderBg
    margin-top: 0
    padding: 20px 0
    background: map-get($basic, 'white')
    .headerItems
        .headerItem
            .nav
                .navLink
                    +typography(18px, 300, map-get($basic, 'black'))
                    @media screen and (max-width: 991px)
                        font-size: 15px
                    @media screen and (max-width: 885px)
                        font-size: 13px

.activeHeader
    background: map-get($basic, 'black')
