@import '../../styles/_typography'
@import  '../../styles/_variables'
.btn
    +typography(24px, 400, map-get($basic, 'white'))
    background: map-get($main, 'main' )
    padding: 12px
    width: 100%
    border: none
    cursor: pointer
    border: 1px solid map-get($main, 'main')
    transition: all 0.5s ease
    display: flex
    justify-content: center
    align-items: center
    column-gap: 10px
    &:hover
        background: transparent
        border: 1px solid map-get($main, 'main')
    @media screen and (max-width: 650px)
        font-size: 16px
    &:disabled
        cursor: no-drop
        background: #737373
