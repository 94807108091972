@import '../../styles/_typography'
@import '../../styles/_variables'

.yt-video-container
  position: relative
  .overlay
    position: absolute
    top: 0
    left: 0
    z-index: 2
    width: 100%
    height: 62px
    background: rgba(0,0,0,0)
